<template>
  <div>
    <v-container class="my-0 my-md-16 py-0  px-0 px-md-3 pb-md-16">
      <v-row class="no-gutters">
        <v-col
          xl="8"
          offset-xl="2"
          md="10"
          offset-md="1"
          sm="12"
          offset-sm="0"
          class="py-0"
        >
          <v-data-table
            :headers="headers"
            :items="dizi"
            :search="search"
            :items-per-page="10"
            sort-by="tarih"
            sort-desc
            :disable-sort="$vuetify.breakpoint.smAndDown ? true : false"
            :class="
              $vuetify.breakpoint.smAndDown ? 'elevation-0' : 'elevation-24'
            "
            style="border-radius:6px"
            @current-items="filtreleme"
          >
            <template v-slot:top>
              <v-toolbar
                flat
                color="primary"
                height="120px"
                dark
                :class="
                  $vuetify.breakpoint.smAndDown
                    ? 'rounded-t-0'
                    : 'rounded-t-lg pl-3'
                "
              >
                <v-toolbar-title class="d-flex align-center">
                  <v-icon large>
                    mdil-chart-line
                  </v-icon>
                  <span class="pl-6 text-h4">{{ $t("rapor") }}</span>
                </v-toolbar-title>
                <v-spacer />
                <div class="d-flex py-6 align-center">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date1"
                        label="Picker without buttons"
                        prepend-icon="mdi-calendar"
                        readonly
                        hide-details=""
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date1"
                      @change="siparisler()"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                  <span class="px-3"></span>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date2"
                        label="Picker without buttons"
                        prepend-icon="mdi-calendar"
                        readonly
                        hide-details=""
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date2"
                      @change="siparisler()"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                  <span class="px-3"></span>

                  <download-excel
                    class="accent pa-3 rounded-lg"
                    style="cursor: pointer"
                    :data="ozelDizi"
                  >
                    {{ $t("export") }}
                  </download-excel>
                </div>
              </v-toolbar>
            </template>

            <template v-slot:header.durum="{ item }">
              {{ $t("durum") }}
            </template>
            <template v-slot:header.tarih="{ item }">
              {{ $t("tarih") }}
            </template>
            <template v-slot:header.tutar="{ item }">
              {{ $t("tutar") }}
            </template>

            <template v-slot:item.durum="{ item }">
              {{ beklemeYazi(item.durum) }}
            </template>
            <template v-slot:item.tarih="{ item }">
              {{ item.tarih | moment("MMMM Do YYYY - HH:mm") }}
            </template>
            <template v-slot:item.tutar="{ item }">
              {{ item.tutar | currency }}
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="my-6 my-sm-0">
                <v-btn
                  class="ml-3"
                  depressed
                  color="primary"
                  :small="$vuetify.breakpoint.smAndDown ? false : true"
                  @click="editItem(item)"
                >
                  <v-icon left>
                    mdi-eye
                  </v-icon>
                  {{ $t("goruntule") }}
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
      scrollable
      max-width="500px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title class="py-3 py-md-6 px-3">
          <div class="text-center text-h4  text-md-h3 font-weight-medium">
            {{ $t("siparisDetay") }}
          </div>
          <v-spacer />

          <v-btn
            x-small
            fab
            elevation="0"
            color="grey lighten-2"
            @click="close()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-0 py-0" style="max-height: 100%;">
          <v-list class="grey lighten-4 pa-0" three-line>
            <template v-for="(item, index) in editedItem.gerceklesen">
              <v-list-item :key="index">
                <v-list-item-avatar
                  size="32"
                  class="mr-4 justify-center"
                  color="grey lighten-2"
                >
                  <span class="text-caption font-weight-medium"
                    >{{ item.miktar }}x</span
                  >
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="mt-1 mb-2">
                    <v-list-item-title class="text-body-1 font-weight-medium">
                      {{ item.ad }}
                    </v-list-item-title>
                  </div>
                  <div v-if="item.kisisellestirmeler" class="mb-2">
                    <v-list-item-subtitle
                      v-for="(kisisellestirme, ki) in item.kisisellestirmeler"
                      :key="ki"
                    >
                      <span>&mdash; {{ kisisellestirme.ad }}</span>

                      <span
                        v-if="kisisellestirme.ilaveFiyat"
                        class="font-weight-bold"
                      >
                        +
                        {{ kisisellestirme.ilaveFiyat | currency }}</span
                      >
                    </v-list-item-subtitle>
                  </div>

                  <v-list-item-subtitle v-if="item.not" class="mb-2">
                    {{ item.not }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="mb-2">
                  <v-chip class="font-weight-bold" label small>
                    {{
                      toplamUrunFiyatHesaplama(
                        item.fiyat,
                        item.miktar,
                        item.kisisellestirmeler
                      ) | currency
                    }}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
              <v-divider />
            </template>
          </v-list>
          <v-list>
            <v-list-item v-if="editedItem.siparisNot" two-line>
              <v-list-item-icon>
                <v-icon color="grey">
                  mdi-comment-text-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t("siparisNotu")
                }}</v-list-item-subtitle>
                <v-list-item-title class="text-body-1">
                  {{ editedItem.siparisNot }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="editedItem.siparisNot" class="grey" />

            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="grey">
                  mdi-calendar-range
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t("siparisTarihi")
                }}</v-list-item-subtitle>
                <v-list-item-title class="text-body-1" v-if="editedItem.tarih">
                  {{ editedItem.tarih | moment("Do MMMM  YYYY  HH:mm") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="grey" />

            <v-list-item three-line>
              <v-list-item-icon>
                <v-icon color="grey">
                  mdi-bank
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>{{ $t("odeme") }}</v-list-item-subtitle>
                <v-list-item-title class="text-caption">
                  {{ this.odemeDurumlar[editedItem.odemeTip - 1] }}
                </v-list-item-title>
                <v-list-item-title class="text-body-1 font-weight-bold">
                  {{ editedItem.tutar | currency }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="grey" />

            <v-list-item three-line>
              <v-list-item-icon>
                <v-icon color="grey">
                  mdi-moped
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle
                  >{{ $t("teslimat") }}
                </v-list-item-subtitle>
                <v-list-item-title class="text-caption">
                  {{ this.teslimatDurumlar[editedItem.teslimatTip] }}
                </v-list-item-title>
                <v-list-item-title class="text-body-1">
                  <span v-if="editedItem.planlama == 1">{{
                    $t("enErkenTeslimat")
                  }}</span>
                  <span v-if="editedItem.planlama == 2">
                    {{ editedItem.date | moment("DD MMMM dddd") }}
                    {{ $t("saat") }}
                    {{ editedItem.time }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="grey" />

            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="grey">
                  mdi-map-marker
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>{{ $t("adres") }}</v-list-item-subtitle>
                <v-list-item-title
                  class="text-body-2"
                  style="white-space: normal;"
                >
                  {{ musteriDeger(editedItem.musteriID, "adres") }}
                  {{
                    bolgeGetir(
                      musteriDeger(editedItem.musteriID, "bolge"),
                      "adres"
                    )
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="grey" />

            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="grey">
                  mdi-account
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>{{ $t("adSoyad") }}</v-list-item-subtitle>
                <v-list-item-title class="text-body-2">
                  {{ musteriDeger(editedItem.musteriID, "ad") }}
                  {{ musteriDeger(editedItem.musteriID, "soyad") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="grey" />

            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="grey">
                  mdi-phone
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>{{ $t("telefon") }}</v-list-item-subtitle>
                <v-list-item-title class="text-body-2">
                  <a
                    class="text-decoration-none black--text"
                    :href="`tel:${musteriDeger(editedItem.musteriID, 'tel')}`"
                    >{{ musteriDeger(editedItem.musteriID, "tel") }}</a
                  >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="grey" />

            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="grey">
                  mdi-email
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>{{ $t("eposta") }}</v-list-item-subtitle>
                <v-list-item-title class="text-body-2">
                  <a
                    class="text-decoration-none black--text"
                    :href="
                      `mailto:${musteriDeger(editedItem.musteriID, 'mail')}`
                    "
                    >{{ musteriDeger(editedItem.musteriID, "mail") }}</a
                  >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import { O2A } from "object-to-array-convert";
import store from "./../store/index";
import { veri } from "./../db";
import Vue from "vue";

var _ = require("lodash");
const lineSmooth = Vue.chartist.Interpolation.cardinal({
  tension: 0
});
export default {
  data() {
    return {
      dialog: false,
      search: "",
      model: "",
      date1: new Date(new Date().setDate(new Date().getDate() - 30))
        .toISOString()
        .substr(0, 10),
      date2: new Date(new Date().setDate(new Date().getDate() + 1))
        .toISOString()
        .substr(0, 10),
      menu1: false,
      modal: false,
      menu2: false,

      durumlar: [
        this.$t("beklemede"),
        this.$t("goruntulendi"),
        this.$t("hazirlaniyor"),
        this.$t("yolda"),
        this.$t("teslimEdildi"),
        this.$t("iptalEdildi"),
        this.$t("silindi")
      ],
      teslimatDurumlar: [this.$t("mekandanTeslim"), this.$t("adreseTeslim")],
      odemeDurumlar: [
        this.$t("kapidaNakit"),
        this.$t("kapidaKK"),
        this.$t("mekandaOdeme"),
        this.$t("paypal")
      ],
      filitreleme: false,
      rapor: false,
      musteriler: [],
      urunler: [],
      kisisellestirmeler: [],
      bolgeler: [],
      filtrelemeArr: [],
      dizi: [],
      ozelDizi: [],
      editedIndex: -1,
      editedItem: {
        urunler: []
      },
      defaultItem: {
        urunler: []
      },
      musteri: "",
      durum: "",
      tarih: "",
      tutar: ""
    };
  },
  firebase: {
    musteriler: veri.ref("musteriler"),
    urunler: veri.ref("urunler"),
    kisisellestirmeler: veri.ref("kisisellestirmeler"),
    bolgeler: veri.ref("bolgeler")
  },
  computed: {
    sales: get("sales/sales"),
    totalSales() {
      return this.sales.reduce((acc, val) => acc + val.salesInM, 0);
    },
    headers() {
      return [
        {
          text: this.$t("durum"),
          value: "durum",
          filter: value => {
            if (!this.durum) return true;
            return value.toUpperCase().indexOf(this.durum.toUpperCase()) >= 0;
          }
        },

        {
          text: this.$t("tarih"),
          value: "tarih",
          filter: value => {
            if (!this.tarih) return true;
            return value.toUpperCase().indexOf(this.tarih.toUpperCase()) >= 0;
          }
        },
        {
          text: this.$t("tutar"),
          value: "tutar",
          filter: value => {
            if (!this.tutar) return true;
            return value.toUpperCase().indexOf(this.tutar.toUpperCase()) >= 0;
          }
        },
        {
          text: "",
          value: "actions",
          align: "right",
          filterable: false
        }
      ];
    }
  },
  mounted() {
    this.siparisler();
  },
  methods: {
    toplamUrunFiyatHesaplama(fiyat, miktar, kisisellestirmeler) {
      return miktar * (fiyat + _.sumBy(kisisellestirmeler, "ilaveFiyat"));
    },
    urunGenelFiyat(val) {
      var kisisellestirmeToplam = _.sumBy(val.kisisellestirme, "ilaveFiyat");

      return val.urunFiyt + kisisellestirmeToplam;
    },

    kisisellestirmeBilgi(val, yer) {
      var kisisellestirme = _.find(this.kisisellestirmeler, {
        ".key": val
      });
      return kisisellestirme[yer];
    },
    urunBilgi(val, yer) {
      var urun = _.find(this.urunler, {
        ".key": val
      });
      return urun[yer];
    },
    editItem(item) {
      var deger = _.find(this.dizi, { object_key: item.object_key });

      this.editedIndex = this.dizi.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    filtreleme(e) {
      this.filtrelemeArr = e;
    },
    siparisler() {
      var myDate1 = this.date1.split("-");
      var newDate1 = new Date(myDate1[0], myDate1[1] - 1, myDate1[2]).getTime();

      var myDate2 = this.date2.split("-");
      var newDate2 = new Date(myDate2[0], myDate2[1] - 1, myDate2[2]).getTime();
      veri
        .ref("siparisler")
        .orderByChild("tarih")
        .startAt(newDate1)
        .endAt(newDate2)
        .on("value", data => {
          if (data.exists()) {
            this.dizi = _.filter(O2A(data), o => {
              if (o.durum >= 4) {
                return o;
              }
            });
            var dizi = JSON.parse(JSON.stringify(this.dizi));
            this.ozelDizi = _.filter(dizi, o => {
              _.set(o, this.$t("musteriID"), o.musteriID);
              _.set(
                o,
                this.$t("musteriAd"),
                o.musteriAd + " " + o.musteriSoyad
              );
              _.set(o, this.$t("adres"), o.musteriAdres);
              _.set(o, this.$t("durum"), this.beklemeYazi(o.durum));
              _.set(o, this.$t("odeme"), this.odemeDurumlar[o.odemeTip - 1]);
              _.set(o, this.$t("tarih"), new Date(o.tarih).toLocaleString());
              _.set(o, this.$t("tutar"), _.replace(o.tutar, ".", ","));

              delete o.durum;
              delete o.durumYazi;
              delete o.gerceklesen;
              delete o.goruntulemeTarih;
              delete o.hazirTarih;
              delete o.musteriAd;
              delete o.musteriAdres;
              delete o.musteriID;
              delete o.musteriSoyad;
              delete o.musteriTel;
              delete o.odemeTip;
              delete o.odemeTipYazi;
              delete o.planlama;
              delete o.siparisNot;
              delete o.tarih;
              delete o.tarihYazi;
              delete o.teslimTarih;
              delete o.teslimatTip;
              delete o.tutar;
              delete o.urunler;
              delete o.yoldaTarih;
              delete o.object_key;

              return o;
            });
            console.log(this.ozelDizi);
          } else {
            this.dizi = [];
          }
        });
    },
    musteriGetir(val) {
      if (this.dialog) {
        const deger = _.find(this.musteriler, {
          ".key": val
        });
        if (deger) {
          return deger.ad + " " + deger.soyad;
        }
      }
    },
    musteriDeger(val, yer) {
      if (this.dialog) {
        const deger = _.find(this.musteriler, {
          ".key": val
        });
        if (deger) {
          return deger[yer];
        }
      }
    },
    bolgeGetir(val) {
      if (this.dialog) {
        const deger = _.find(this.bolgeler, {
          ".key": val
        });
        if (deger) {
          return deger.ad + ", " + deger.kodu;
        }
      }
    },
    sil(item) {
      confirm(this.$t("silOnay")) &&
        veri
          .ref("siparisler/" + item)
          .remove()
          .then(() => {
            this.close();
          });
    },
    beklemeRenk(val) {
      let deger;
      if (val == 1) {
        deger = "error";
      } else if (val == 2) {
        deger = "warning";
      } else if (val == 3) {
        deger = "success";
      } else if (val == 4) {
        deger = "secondary";
      } else if (val == 5) {
        deger = "grey";
      }
      return deger;
    },
    beklemeYazi(val) {
      return this.durumlar[val];
    }
  }
};
</script>

<style lang="sass"></style>
